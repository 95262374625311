import React from 'react';
import './ContactForm.scss';
//import Image from '../components/Image';
//import Leads from '../components/Leads/index'
import { RenderShortcode } from '../components/RenderShortcode/RenderShortcode'
export const ContactForm = props => {
  const {
    theme,
    formType,
    subheading,
    heading,
    content
  } = props
  return (
    <section className={`ContactForm ${theme}`}>
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="form-container">
            <span className="subtitle" dangerouslySetInnerHTML={{__html: subheading}} />
            <div className="wysiwygcontent">
              <h2 className="heading" dangerouslySetInnerHTML={{__html: heading}} />
              <RenderShortcode content={content}/>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
