import React from 'react'
import { Image } from '../../components/Image';
import RenderContent from '../../components/RenderContent'
import './BiographyList.scss'

export const BiographyList = props => {
  return (
    <section
      className={
        props.className ? props.className : 'biography-list__container'
      }
    >
      <div className="wrapper">
        <div className="flex-row__wrapper">
          <MyItem items={props} />
        </div>
      </div>
    </section>
  )
}

const MyItem = props => {
  const { bioContent } = props.items;
  if (!bioContent) return null;
  const toggleIsClicked = () => {
    if (event.target == null) {
      return null;
    }

    // using synthetic events
    let button = event.target;
    if (event.target.parentNode.previousSibling.classList.contains('toggle')) {
      let parentSibling = event.target.parentNode.previousSibling;
      let readMoreSpan = event.target.parentNode.querySelector('.read-more__text');
      if (parentSibling.classList.contains('toggle-show')) {

        readMoreSpan.innerText = 'Read More'
        parentSibling.classList.toggle('toggle-show')
        button.classList.toggle('expanded');
      }
      else {
        readMoreSpan.innerText = 'Read Less'
        parentSibling.classList.toggle('toggle-show')
        button.classList.toggle('expanded');
      }
    }
  }

  return bioContent.map(item => (
    <div className="flex-row" key={item.name}>
      <div className="flex-col">
        <div className="staff-image__wrapper">
          <Image src={item.profile_image.source_url} alt={item.profile_image.alt_text} />
        </div>
      </div>
      <div className="flex-col">
        <p className="bio-name notranslate">{item.name}</p>
        <p className="bio-title">{item.title}</p>
        <p>{item.summary}</p>
        <div className="read-more">
          <div className="toggle">
            <RenderContent content={item.bioText} />
          </div>
          <div className="button-wrapper">
            <button className="read-more__button" onClick={toggleIsClicked}>
            </button>
            <span className="read-more__text">Read More</span>
          </div>
        </div>
      </div>
    </div>
  ))
}
