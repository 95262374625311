import React from 'react'
import './ImageAndText.scss'
import { Image } from '../components/Image'
import { Link } from 'gatsby'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'

// Sets the percentage required for the onVisible to render
setDefaultProps({
  percent: 30,
})

export const ImageAndText = props => {
  const {
    imagePlacement,
    theme,
    style,
    subheading,
    isSubheadingClass,
    heading,
    content,
    button,
    buttonText,
    buttonHash,
    image,
  } = props
  const buttonUrl = `${button}${buttonHash && `${buttonHash}`}`
  return (
    <section className={`ImageAndText ${imagePlacement} ${theme} ${style}`}>
      {/* <OnVisible percent={70}> */}
        <div className="background-helper"></div>
        <div className="wrapper">
          <div className="content-overlay">
            <div className="flex-row">
              <ParallaxProvider>
                <OnVisible percent={50} className="content image-box">
                  <Parallax
                    className="custom-class"
                    y={[-16, 16]}
                    tagOuter="figure">
                    {image && <Image src={image} position="relative"/>}
                    <div className="image-mask"></div>
                  </Parallax>
                </OnVisible>
              </ParallaxProvider>

              <OnVisible
                percent={50}
                className={`content text-box tb2 ${subheading ? '' : 'no-subtitle'} ${isSubheadingClass ? 'subtitle-padding' : ''}`}
              >
                {subheading && (
                  <span
                    className="subtitle"
                    dangerouslySetInnerHTML={{ __html: subheading }}
                  />
                )}
                {heading && (
                  <h2
                    className="title"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                )}
                <div
                  className="wysiwygcontent"
                  dangerouslySetInnerHTML={{ __html: content }}
                />
                {buttonUrl && (
                  <Link
                    to={buttonUrl}
                    activeClassName="active"
                    className={`button ${theme === 'dark' ? 'on-dark' : ''}`}
                    aria-current="true"
                  >
                    <span>{buttonText ? buttonText : 'Learn More'}</span>
                  </Link>
                )}
              </OnVisible>
            </div>
          </div>
        </div>
    {/* </OnVisible> */}
    </section>
  )
}
