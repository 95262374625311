import React from 'react';
import './MobileInformation.scss'
import Slider from 'react-slick';

export const MobileInformation = (props) => {
  const {
    state
  } = props
  const {
    mapRegions,
    activeRegion,
    activeLocation,
  } = state

  let sliderSettings = {
    dots: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false
  };

  return (
    <div className="mobile-location-slider">
    <Slider {...sliderSettings}>
      <div className="slide">
        <div className="inner">
          <div className="stat">
            {props.props.regionIcon && props.props.regionIcon.source_url &&
              <div className="icon">
                <img src={props.props.regionIcon.source_url}/>
              </div>
            }
            <div className="title">Global</div>
          </div>
          <div className="stat">
            <span className="value">{props.props.projectsTotal && props.props.projectsTotal}<span className="counter-char">+</span></span>
            <span className="label">Projects</span>
          </div>
          <div className="stat">
            <span className="value">{props.props.studiesTotal && props.props.studiesTotal}</span>
            <span className="label">Studies</span>
          </div>
          <div className="stat">
            <span className="value">{props.props.commodities && props.props.commodities}</span>
            <span className="label">Commodities</span>
        </div>
        </div>
      </div>
      {mapRegions && mapRegions.map((region, index) => region.regionName && (
        <div key={index} className="slide">
          <div className="inner">
            <div className="stat">
              {region.regionIcon && region.regionIcon.source_url &&
                <div className="icon">
                  <img src={region.regionIcon.source_url}/>
                </div>
              }
              <div className="label">{region.regionName}</div>
            </div>
            <div className="stat">
              <span className="value">{region.projectTotal && region.projectTotal}<span className="counter-char">+</span></span>
              <span className="label">Projects</span>
            </div>
            <div className="stat">
              <span className="value"><span className="counter-char"></span>{region.studiesTotal && region.studiesTotal}</span>
              <span className="label">Studies</span>
            </div>
            <div className="stat">
              {region.commodities && <span className="value" dangerouslySetInnerHTML={{__html: region.commodities}} />}
              <span className="label">Commodities</span>
            </div>
          </div>
        </div>
      ))}
    </Slider>
    </div>
  )
}
