import React, { Component } from "react"
import { StaticQuery, graphql } from 'gatsby';
import GoogleMapReact from 'google-map-react';
import './Projects.scss'
// import CountUp from 'react-countup';
import { slugify, isClient, compileQuery } from '../../utils/helpers'
import GatsbyLink from '../../components/GatsbyLink'
import CustomCountUp from '../../components/CustomCountUp'
import 'url-search-params-polyfill';
const settings = require('./map-settings.js');
import { MapLocation } from './MapLocation'
import { Information } from './Information'
import { MobileInformation } from './MobileInformation'
import { Menu } from './Menu'


class ThisProjects extends Component {
  constructor(props) {
    //Check if querystring location
    if (isClient) {
      const urlParams = new URLSearchParams(window.location.search);
      var regionFilter = urlParams.get('region');
    }

    super(props);
    const { regions } = this.props
    var regionIndex = regionFilter ? regions.findIndex(i => slugify(i.regionName) === regionFilter) : null
    this.state = {
      activeRegion: regionIndex ? regionIndex : null,
      activeLocation: null,
      mapRegions: null, //All regions, locations and projects
      activeCenter: null,
      openInformation: false,
      mapZoom: 2, //Default map zoom
      transitionDirection: null
    }
  }

  loadData() {
    //Build new array of locations using regions as the index
    let newRegions = []
    const { data, regions } = this.props;

    regions && regions.map((region, index) => {
      newRegions.push({
        regionIndex: index,
        regionName: region.regionName,
        projectTotal: region.projectTotal,
        studiesTotal: region.studiesTotal,
        commodities: region.commodities,
        regionIcon: region.regionIcon,
        locations: [],
      })
    })

    const csvJson = data.allWordpressProjectsAll.edges
    csvJson && csvJson.map(uIregion => {
      if (uIregion.node.region.length > 0 && uIregion.node.location.length > 0) {
        let regionIndex;
        regionIndex = newRegions.findIndex(i => i.regionName.trim() === uIregion.node.region.trim())
        if (regionIndex === -1) {
          //Add New
          return newRegions.push({
            locations: [{
              location: uIregion.node.location,
              lat: uIregion.node.lat,
              lng: uIregion.node.lng,
              projects: [{
                title: uIregion.node.project,
                commodity: uIregion.node.commodity,
                client: uIregion.node.client,
                slug: uIregion.node.slug
              }]
            }]
          })
        } else {
          //Extend region
          let locationsIndex = newRegions[regionIndex].locations.findIndex(i => i.location.trim() === uIregion.node.location.trim())
          if (locationsIndex === -1) {
            //Add new location
            return newRegions[regionIndex].locations.push({
              location: uIregion.node.location,
              lat: uIregion.node.lat,
              lng: uIregion.node.lng,
              projects: [{
                title: uIregion.node.project,
                commodity: uIregion.node.commodity,
                client: uIregion.node.client,
                slug: uIregion.node.slug
              }]
            })
          } else {
            //Extend locations
            return newRegions[regionIndex].locations[locationsIndex].projects.push({
              title: uIregion.node.project,
              commodity: uIregion.node.commodity,
              client: uIregion.node.client,
              slug: uIregion.node.slug
            })
          }
        }
      }

    })

    this.setState({
      mapRegions: newRegions,
    })
  }

  componentDidMount() {
    this.loadData();
  }

  changeRegion = (event, region) => {
    event.preventDefault();
    const { mapRegions } = this.state
    const { regionName, projectTotal, studiesTotal, commodities, regionIcon, regionIndex } = mapRegions[region]

    this.setState({
      activeRegion: regionIndex,
      activeLocation: null,
      mapZoom: 2,
      transitionDirection: 'slideInRight'
    })
  }

  allRegion = (event) => {
    event.preventDefault();
    this.setState({
      activeRegion: null,
      activeLocation: null,
      activeCenter: null,
      mapZoom: 2,
      transitionDirection: 'slideInLeft'
    })
  }

  toggleMapLocation = (event, newIndex, centerLat, centerLng, region) => {
    const { mapRegions } = this.state
    event.preventDefault();
    const { regionIcon, projectTotal, studiesTotal, commodities } = mapRegions[region]
    this.setState({
      activeRegion: region,
      activeLocation: newIndex,
      activeCenter: {
        lat: parseFloat(centerLat),
        lng: parseFloat(parseInt(centerLng))
      },
      mapZoom: 4,
      transitionDirection: 'slideInRight'
    })
  }

  allLocations = (event) => {
    event.preventDefault();
    this.setState({
      activeLocation: null,
      activeCenter: null,
      mapZoom: 2,
      transitionDirection: 'slideInLeft'
    })
  }

  toggleInformation = (event) => {
    event.preventDefault();
    this.setState({
      openInformation: this.state.openInformation ? false : true
    })
  }

  render() {
    const {
      mapRegions,
      activeRegion,
      activeLocation,
      activeCenter,
      regionIcon,
      projectTotal,
      studiesTotal,
      commodities,
      mapZoom,
      transitionDirection
    } = this.state

    const { data } = this.props

    var minLat = 0
    var minLng = 0
    var maxLat = 0
    var maxLng = 0

    if (activeRegion !== null) {
      //Max min lat lng
      mapRegions[activeRegion] && mapRegions[activeRegion].locations.map((location, index) => {
        minLat = location.lat && Math.abs(parseInt(location.lat)) < Math.abs(parseInt(minLat)) || minLat === 0 ? parseInt(location.lat) : minLat
        maxLat = location.lat && Math.abs(parseInt(location.lat)) > Math.abs(parseInt(maxLat)) || maxLat === 0 ? parseInt(location.lat) : maxLat
        minLng = location.lng && Math.abs(parseInt(location.lng)) < Math.abs(parseInt(minLng)) || minLng === 0 ? parseInt(location.lng) : minLng
        maxLng = location.lng && Math.abs(parseInt(location.lng)) > Math.abs(parseInt(maxLng)) || maxLng === 0 ? parseInt(location.lng) : maxLng
      })
    }
    //work out center from min max
    var centerLat = ((parseInt(maxLat) - parseInt(minLat)) / 2) + parseInt(minLat)
    var centerLng = ((parseInt(maxLng) - parseInt(minLng)) / 2) + parseInt(minLng) //+ 10

    var center = activeCenter ? activeCenter : { lat: parseFloat(centerLat), lng: parseFloat(centerLng) }

    const createMapOptions = (maps) => {
      return {
        scrollwheel: false,
        disableDefaultUI: false,
        mapTypeId: 'roadmap',
        styles: settings,
        maxZoom: 15,
        minZoom: 3,
      };
    };

    //Display loading untill new data structure created
    if (!mapRegions) {
      return (
        <div className="loading" />
      )
    }

    if (mapRegions) {
      return (
        <section className="ProjectsSection notranslate">
          <MobileInformation
            state={this.state}
            props={this.props}
          />

          <div className="map-over">

            <Menu
              state={this.state}
              changeRegion={this.changeRegion}
              toggleMapLocation={this.toggleMapLocation}
              allRegion={this.allRegion}
              allLocations={this.allLocations}
            />


            <Information
              state={this.state}
              props={this.props}
              toggleInformation={this.toggleInformation}
            />


          </div>
          {isClient && (
            <div className="google-map" id="map">
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyDlltAp88xArdwEVGAvYM-cBg-Mzre0ccM' }}
                options={createMapOptions}
                center={center}
                zoom={mapZoom}
              >
                {activeRegion ?
                  mapRegions[activeRegion].locations.map((location, index) => location.lat && location.lng && (
                    <MapLocation
                      lat={parseFloat(location.lat)}
                      lng={parseFloat(location.lng)}
                      key={index}
                      index={index}
                      location={location}
                      toggleMapLocation={this.toggleMapLocation}
                      active={activeLocation}
                      region={activeRegion}
                    />
                  ))
                  :
                  mapRegions.map((region, regionIndex) => region.locations && region.locations.map((location, index) => location.lat && location.lng && (
                    <MapLocation
                      lat={parseFloat(location.lat)}
                      lng={parseFloat(location.lng)}
                      key={index}
                      index={index}
                      location={location}
                      toggleMapLocation={this.toggleMapLocation}
                      active={activeLocation}
                      region={regionIndex}
                    />
                  )))
                }
              </GoogleMapReact>
            </div>
          )}
        </section>
      )
    }
  }
}


export const Projects = (props) => {
  return (
    <StaticQuery
      query={graphql`
          query {
            allWordpressProjectsAll {
              edges {
                node {
                  id
                  wordpress_id
                  type
                  throughput
                  project
                  lng
                  lat
                  region
                  location
                  commodity
                  client
                  slug
                }
              }
            }
          }
        `}
      render={data => <ThisProjects {...props} data={data} />}
    />
  );
}
