import React, { useState, useEffect, useRef } from 'react'
import './ContentWithSidebar.scss'
import { Link } from 'gatsby'
import GatsbyLink from '../components/GatsbyLink'
import { isClient } from '../utils/helpers'

export const ContentWithSidebar = props => {
  const { sidebarLabel, enableDisable, sections, id } = props

  const [activeItem, setActiveItem] = useState(null)

  const myRef = useRef()

  const changeItem = index => {
    setActiveItem(`${id}-${index.toString()}`)
    setTimeout(myRef.current.scrollIntoView({ behavior: 'smooth' }), 100)
  }

  const changeItemMobile = index => {
    const newActiveItem =
      activeItem == `${id}-${index.toString()}`
        ? null
        : `${id}-${index.toString()}`
    setActiveItem(newActiveItem)
    setTimeout(myRef.current.scrollIntoView({ behavior: 'smooth' }), 100)
  }

  useEffect(() => {
    setActiveItem(isClient && window.innerWidth > 960 ? `${id}-0` : null)

    if (location.hash) {
      if (location.hash.includes('resources')) {
        setTimeout(changeItem(0), 100)
      }
      if (location.hash.includes('industrial-processes')) {
        setTimeout(changeItem(1), 100)
      }
      if (location.hash.includes('infrastructure')) {
        setTimeout(changeItem(2), 100)
      }
    }
  }, [])

  return (
    <div>
      <span className="anchor-link" ref={myRef}></span>
      <section className={`ContentWithSidebar`}>
        <div className="background-helper"></div>
        <div className="wrapper">
          <div className="content-overlay">
            <div className="flex-row">
              <div className="content sidebar">
                <div className="inner-sidebar">
                  {sidebarLabel ? (
                    <div className="sidebar-label-container">
                      <span
                        className="sidebar-label"
                        dangerouslySetInnerHTML={{ __html: sidebarLabel }}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                  {sections.map((item, index) => {
                    const { heading, icon } = item
                    return (
                      <div
                        key={index}
                        className={`sidebar-item ${
                          icon.source_url ? '' : 'no-icon'
                        } ${
                          activeItem == `${id}-${index.toString()}`
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => changeItem(index)}
                      >
                        {icon.source_url && (
                          <img className={`image`} src={`${icon.source_url}`} />
                        )}
                        <span className="heading">{heading}</span>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="content content-side">
                {sidebarLabel ? (
                  <div className="sidebar-label-container">
                    <span
                      className="sidebar-label"
                      dangerouslySetInnerHTML={{ __html: sidebarLabel }}
                    />
                  </div>
                ) : (
                  ''
                )}
                {sections.map((item, index) => {
                  const {
                    heading,
                    content,
                    buttonOne,
                    buttonOneText,
                    buttonOneHash,
                    buttonTwo,
                    buttonTwoText,
                    buttonTwoHash,
                    contactCards,
                  } = item
                  const buttonOneUrl = `${buttonOne}${buttonOneHash &&
                    `${buttonOneHash}`}`
                  const buttonTwoUrl = `${buttonTwo}${buttonTwoHash &&
                    `${buttonTwoHash}`}`
                  return (
                    <div className="content-item-container" key={index}>
                      <span className="anchor-link"></span>
                      <button
                        className={`mobile-accordion-button ${
                          activeItem == `${id}-${index.toString()}`
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => changeItemMobile(index)}
                      >
                        {heading}
                      </button>
                      <div
                        className={`content-item ${
                          activeItem == `${id}-${index.toString()}`
                            ? 'show'
                            : 'hide'
                        }`}
                      >
                        <h2 className="heading">{heading}</h2>
                        {enableDisable &&
                        enableDisable.includes('enableContent') ? (
                          <div
                            className="wysiwygcontent"
                            dangerouslySetInnerHTML={{ __html: content }}
                          />
                        ) : (
                          ''
                        )}
                        {enableDisable &&
                        enableDisable.includes('enableButtons') ? (
                          <div className="buttons">
                            {buttonOneUrl && (
                              <Link
                                to={buttonOneUrl}
                                activeClassName="active"
                                className={`button`}
                                aria-current="true"
                              >
                                <span>
                                  {buttonOneText ? buttonOneText : 'Learn More'}
                                </span>
                              </Link>
                            )}
                            {buttonTwoUrl && (
                              <Link
                                to={buttonTwoUrl}
                                activeClassName="active"
                                className={`button`}
                                aria-current="true"
                              >
                                <span>
                                  {buttonTwoText ? buttonTwoText : 'Learn More'}
                                </span>
                              </Link>
                            )}
                          </div>
                        ) : (
                          ''
                        )}
                        {enableDisable &&
                        enableDisable.includes('enableContactCards') ? (
                          <div className="contact-card-container notranslate">
                            {contactCards.map((item, index) => {
                              const {
                                locationName,
                                streetAddress,
                                mailingAddress,
                                telephone,
                                fax,
                                googleMapsLink,
                              } = item
                              return (
                                <div className="contact-card" key={index}>
                                  {locationName && (
                                    <span
                                      className="location-name"
                                      dangerouslySetInnerHTML={{
                                        __html: locationName,
                                      }}
                                    />
                                  )}
                                  {streetAddress && (
                                    <span
                                      className="street-address"
                                      dangerouslySetInnerHTML={{
                                        __html: streetAddress,
                                      }}
                                    />
                                  )}
                                  {mailingAddress && (
                                    <span
                                      className="mailing-address"
                                      dangerouslySetInnerHTML={{
                                        __html: mailingAddress,
                                      }}
                                    />
                                  )}
                                  {telephone && (
                                    <span className="telephone">
                                      Telephone:{' '}
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html: telephone,
                                        }}
                                      />
                                    </span>
                                  )}
                                  {fax && (
                                    <span className="fax">Fax: {fax}</span>
                                  )}
                                  {googleMapsLink && (
                                    <GatsbyLink
                                      to={googleMapsLink}
                                      className={`button`}
                                      aria-current="true"
                                      target="_blank"
                                    >
                                      View On Maps
                                    </GatsbyLink>
                                  )}
                                </div>
                              )
                            })}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
