import React from 'react';
import OnVisible, { setDefaultProps } from 'react-on-visible';
import { Link } from 'gatsby';
import './ContactUsBox.scss';
import { Image } from '../../components/Image'

export const ContactUsBox = props => {
  const {
    subtitle,
    heading,
    content,
    buttonLink,
    buttonText,
    image,
    headingColour,
    backgroundColour
  } = props
  return (
    <section
      className={`contact-us-box TextBlock ${backgroundColour && backgroundColour}`}>
      {backgroundColour == 'Light' && <div className="background-helper"></div>}
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <OnVisible className="content text-box tb2">
              <div className="content-wrapper">
                { subtitle && <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} /> }
                {!subtitle && <span className="subtitle" style={{display: 'none'}}></span>}
                { heading &&
                    <h2 className={`${headingColour}-title title`}
                    dangerouslySetInnerHTML={{__html: heading}} />
                }
                <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: content}} />
                {buttonLink && (
                  <Link
                    to={buttonLink}
                    activeClassName="active"
                    className={`button`}
                    aria-current="true"
                  >
                    <span>{ buttonText ? buttonText : 'Learn More' }</span>
                  </Link>
                )}
                </div>
                <div className="image-wrapper">
                  {image && <Image src={image} position={"relative"} />}
                </div>
              </OnVisible>
          </div>
        </div>
      </div>
    </section>
  )
}
