import React from 'react';
import { FaTimes } from 'react-icons/fa';
import GatsbyLink from '../../components/GatsbyLink'
import { slugify, compileQuery } from '../../utils/helpers'
export const MapLocation = (props) => {
  const { location, toggleMapLocation, active, index, region } = props
  const isOpen = active === index;
  return (
      <div className={`marker${isOpen ? ' active' : ''}`}>
        <span onClick={event => toggleMapLocation(event, index, location.lat, location.lng, region )}/>
      </div>
  )
}
