import React, { useState, useEffect } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'
import OnVisible from 'react-on-visible'
import { Image } from '../../components/Image'
//import GatsbyLink from '../../components/GatsbyLink'
import { Carousel } from '../../components/Carousel/Carousel'
//import { decodeEntities } from '../../utils/htmlParse'
import './FeaturedProjectsSliderFeed.scss'
import './AliceCarousel.scss'

// Functional component which takes in a large block of text
// and returns a shortened word limit version
const ReducedSummary = props => {
  const summaryText = props.summary
  const classNameValue = props.className || ''
  let wordLimit = props.wordLimit || 25

  let split = summaryText.split(' ')
  if (split.length < wordLimit) {
    wordLimit = split.length
  }

  let reducedString = []
  for (let i = 0; i < wordLimit; i++) {
    reducedString.push(split[i])
  }

  reducedString.push(' ...')
  reducedString = reducedString.join(' ')
  return <p className={classNameValue}>{reducedString}</p>
}

const ProjectsFeedOutput = props => {
  const {
    optionalText,
    //manuallySelectCaseStudies,
    caseStudiesList,
    //customFilterLink,
    customButtonText,
    custom_class,
    data,
  } = props
  const { caseStudies, location, sector, featuredProjects } = data

  //const [isTabletView, setIsTabletView] = useState(false)
  const [isMobileView, setIsMobileView] = useState(false)
  const [dotsDisabled, setDotsDisabled] = useState(true)
  const [stagePadding, setStagePadding] = useState({
    paddingLeft: 250,
    paddingRight: 250,
  })
  const [postList, setPostList] = useState([])

  useEffect(() => {
    let menuBreakPoint = 1199
    let mobileBreakPoint = 440
    if (window) {
      let windowWidth = window.outerWidth
      if (windowWidth < menuBreakPoint) {
        if (windowWidth < mobileBreakPoint) {
          setIsMobileView(true)
        }
        //setIsTabletView(true)
        setStagePadding({
          paddingLeft: 0,
          paddingRight: 0,
        })
      }
    }
  }, [])

  useEffect(() => {
    console.log('useEffect')
    const filteredCaseStudies = caseStudies.edges.filter(cs =>
      caseStudiesList
        .map(
          csl => parseInt(csl.featuredCaseStudyItem.guid.match(/\d+$/)[0]),
          10
        )
        .includes(cs.node.wordpress_id)
    )

    let sortArray = []

    // Loop through each project and get the post_title for comparison
    featuredProjects.caseStudiesList.map(project =>
      sortArray.push(project.featuredCaseStudyItem.post_title)
    )

    const filteredSortedCaseStudies = filteredCaseStudies
      .slice()
      .sort(function(a, b) {
        return sortArray.indexOf(a.node.title) - sortArray.indexOf(b.node.title)
      })

    setPostList(filteredSortedCaseStudies)
  }, [])

  //render() {

  /**
   * We are using the allWpCaseStudies graphql query so that we can retrieve further post data
   * such as the featured image and slug.
   */
  //const { isMobileView } = this.state
  //const { featuredCaseStudyItem, featuredCaseStudyItemInfo } = caseStudiesList

  /**
   * Sort the filtered case studies by post-title
   */

  // Initialise an array to hold the sorting order from ACF input order

  // Return a new array (without mutating the old one), hence using slice which matches the sorted order of sortArray

  // Finally use the new array to map over the filtered & sorted case studies.
  const myArr = postList.map((cs, index) => (
    <div className="flex-item__alice" key={index}>
      <div className="image-text__wrapper">
        <div className="image-container">
          <Image className="featured-image" src={cs.node.featuredImage} />
        </div>
        <div className="text-container">
          <div className="text-inner-wrapper">
            <span
              className="title notranslate"
              dangerouslySetInnerHTML={{
                __html: cs.node.title,
              }}
            />
            {cs.node.sector[0] ? (
              <span
                className="line commodity"
                dangerouslySetInnerHTML={{
                  __html: cs.node.sector[0]
                    ? sector.edges.find(
                        edge => edge.node.wordpress_id == cs.node.sector[0]
                      ).node.name
                    : '',
                }}
              ></span>
            ) : (
              'Sector'
            )}
            {cs.node.location[0] ? (
              <span className="line location notranslate">
                {cs.node.location[0]
                  ? location.edges.find(
                      l => l.node.wordpress_id == cs.node.location[0]
                    ).node.name
                  : ''}
              </span>
            ) : (
              'Location'
            )}
            <span className="line line__last notranslate">
              {cs.node.acf.companyName
                ? cs.node.acf.companyName
                : 'Company Name'}
            </span>
            {cs.node.acf.summary &&
            cs.node.acf.summary.length > 1 &&
            isMobileView ? (
              <ReducedSummary
                summary={cs.node.acf.summary}
                wordLimit={20}
                className={'blurb'}
              />
            ) : (
              <p className="blurb">{cs.node.acf.summary}</p>
            )}
          </div>
          <div className="view-all-button">
            <Link
              to={`/case-studies/${cs.node.slug}/`}
              activeClassName="active"
              className="button"
              aria-current="true"
            >
              <span>
                {customButtonText ? customButtonText : 'View Case Study'}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  ))

  const aliceSliderSettings = {
    dotsDisabled: dotsDisabled,
    infinite: true,
    keysControlDisabled: true,
    showSlideInfo: false,
    duration: 450,
    fadeOutAnimation: true,
    stagePadding: stagePadding,
    responsive: {
      0: {
        items: 1,
      },
      1024: {
        items: 1,
      },
    },
    //onSlideChanged: e => handleOnSlideChange(e, myArr.length),
  }

  // const handleOnSlideChange = (e, length) => {
  //   if (e.slide === 0) {
  //   }
  // }

  return (
    <section
      className={`FeaturedProjectsFeed ${custom_class ? custom_class : ''}`}
    >
      <div className="wrapper">
        <div className="content-wrapper">
          {optionalText && (
            <div
              className="wysiwygcontent"
              dangerouslySetInnerHTML={{ __html: optionalText }}
            />
          )}
        </div>
      </div>
      <OnVisible className="content-overlay">
        <div className="flex-row">
          {/* ALICE CAROUSEL */}
          <Carousel settings={aliceSliderSettings}>{myArr}</Carousel>
        </div>
      </OnVisible>
    </section>
  )
  //}
}

export const FeaturedProjectsSliderFeed = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          caseStudies: allWordpressWpCaseStudies {
            edges {
              node {
                slug
                title
                location
                sector
                acf {
                  companyName
                  summary
                  isFeatured
                }
                wordpress_id
                featuredImage: featured_image_url {
                  source_url
                }
              }
            }
          }
          location: allWordpressWpLocation(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          sector: allWordpressWpSector(filter: { count: { gt: 0 } }) {
            edges {
              node {
                name
                slug
                wordpress_id
                wordpress_parent
              }
            }
          }
          featuredProjects: wordPressAcfFeaturedProjectsSliderFeed {
            caseStudiesList {
              featuredCaseStudyItem {
                wordpress_id
                post_title
              }
            }
            id
            manuallySelectCaseStudies
          }
        }
      `}
      render={data => <ProjectsFeedOutput {...props} data={data} />}
    />
  )
}
