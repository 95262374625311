import React from 'react';
import { FaTimes } from 'react-icons/fa';
import GatsbyLink from '../../components/GatsbyLink'
import { slugify, compileQuery } from '../../utils/helpers'
import './Information.scss'
export const Information = (props) => {
  const {
    state,
    toggleInformation
  } = props
  const {
    mapRegions,
    activeRegion,
    activeLocation,
    activeCenter,
    transitionDirection,
    openInformation
  } = state

  return (
    <div className={`region-details${openInformation ? ' open' : ''}`} onClick={event => toggleInformation(event)}>
      <div className="tab">{openInformation ? 'HIDE KEY NUMBERS' : 'SHOW KEY NUMBERS'}</div>
      <div className="wrap">
      {activeRegion === null && !activeLocation ?
        <>
          <div className={`inner ${transitionDirection}`}>
            <div className="stat">
              {props.props.regionIcon && props.props.regionIcon.source_url &&
                <div className="icon">
                  <img src={props.props.regionIcon.source_url}/>
                </div>
              }
              <div className="title">Global</div>
            </div>
            <div className="stat">
              <span className="value">{props.props.projectsTotal && props.props.projectsTotal}</span>
              <span className="label">Projects</span>
            </div>
            <div className="stat">
              <span className="value">{props.props.studiesTotal && props.props.studiesTotal}</span>
              <span className="label">Studies</span>
            </div>
            <div className="stat">
              <span className="value">{props.props.commodities && props.props.commodities}</span>
              <span className="label">Commodities</span>
            </div>
          </div>
        </>
      :
      <>
        <div className={`inner ${transitionDirection}`}>
          <div className="stat">
          {mapRegions[activeRegion].regionIcon && mapRegions[activeRegion].regionIcon.source_url &&
            <div className="icon">
              <img src={mapRegions[activeRegion].regionIcon.source_url}/>
            </div>
          }
            <div className="label">{mapRegions[activeRegion].regionName}</div>
          </div>
          <div className="stat">
            <span className="value">{mapRegions[activeRegion].projectTotal && mapRegions[activeRegion].projectTotal}<span className="counter-char">+</span></span>
            <span className="label">Projects</span>
          </div>
          <div className="stat">
            <span className="value"><span className="counter-char"></span>{mapRegions[activeRegion].studiesTotal && mapRegions[activeRegion].studiesTotal}</span>
            <span className="label">Studies</span>
          </div>
          <div className="stat">
            {mapRegions[activeRegion].commodities && <span className="value" dangerouslySetInnerHTML={{__html: mapRegions[activeRegion].commodities}} />}
            <span className="label">Commodities</span>
          </div>
        </div>
      </>
      }
      </div>
    </div>
  )
}
