import React, { useState, useEffect } from 'react'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import { Link } from 'gatsby'
import { Image } from '../../components/Image';
import './HomeTextAndVideoImage.scss';



export const HomeTextAndVideoImage = props => {
  const [isVideoClicked, setIsVideoClicked] = React.useState(false);
  
  const {
    subtitle,
    heading,
    copyContent,
    image,
    imageAccent,
    buttonLink,
    buttonText,
    videoLink
   } = props;
   const handleClick = () => {
     setIsVideoClicked(!isVideoClicked)
   }
   return (
    <section className="home-text-and-video">
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <OnVisible percent={10} className="content text-box tb2">
              {subtitle && <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />}
              {heading && <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} />}
              {copyContent && <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: copyContent}} />}
              <div className="image-wrapper" onClick={handleClick}>
                {image && 
                  <Image 
                    src={image}
                    className={`video-thumb${isVideoClicked ? ' video-thumb__hidden' : ''}`} 
                    position="relative"
                  />
                }
                {videoLink && isVideoClicked ?
                <div className="video-embed iframe-block"
                  dangerouslySetInnerHTML={{__html: videoLink}}>
                </div>
                  : <div className="video-embed" />
                  }
                {/* {imageAccent && <div className="image-accent">
                  <div className="block-one">
                  </div>
                  <div className="block-two">
                  </div>
                </div>} */}
              </div>
              {buttonLink && (
                  <Link
                    to={buttonLink}
                    activeClassName="active"
                    className="button"
                    aria-current="true"
                  >
                    <span>{ buttonText ? buttonText : 'Join Us' }</span>
                  </Link>
              )}
            </OnVisible>
          </div>
        </div>
      </div>
    </section>
    )
}
