import React from 'react'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import { Parallax, ParallaxProvider } from 'react-scroll-parallax'
import { Image } from '../../components/Image';
import './HomeThreeImage.scss'

const isClient = typeof window !== 'undefined';

export const HomeThreeImage = props => {
  const { images, backgroundImage } = props;
  const { imageLeft, imageRightTop, imageRightBot } = images[0];

  // Function to toggle a class on the body
  function changeColor() {
    let parentSection = document.querySelector('.home-text-and-video');
    if (parentSection) {
      setBodyClass('new-bg');
    }
  }

  function setBodyClass(className) {
    if (isClient) {
      let bodyElem = document.querySelector('body');
      window.requestAnimationFrame(function() {
        bodyElem.classList.toggle(className);
      })
    }
  }

  const { textContent, heading, subtitle } = props.textContent;
  return (
    <section className="home-three-image">
      <div id='home-three-image-wrapper' className="background-image__wrapper image-multiply">
      { backgroundImage && (
        <Image className="background-image" src={backgroundImage} position="relative" />
      ) }
      </div>
      <div className="section-wrapper light-content">
          <div className="flex-row">
            <div className="flex-col column-one">
              <div className="content">
                <span className="subtitle" dangerouslySetInnerHTML={{__html: subtitle}} />
                <h2 className="title" dangerouslySetInnerHTML={{__html: heading}} />
                <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: textContent}} />
              </div>
              <div className="image-wrapper">
              <ParallaxProvider>
                <OnVisible percent={50} className="content image-box">
                <Parallax
                    className="custom-class"
                    y={[-14, 14]}
                    tagOuter="figure">
                  {imageLeft && <Image src={imageLeft} position="relative" />}
                  <div className="image-mask"></div>
                </Parallax>
                </OnVisible>
              </ParallaxProvider>
              </div>
            </div>
            <div className="flex-col">
              <div className="image-wrapper">
              <ParallaxProvider>
              <OnVisible percent={50} className="content image-box">
              <Parallax
                    className="custom-class"
                    y={[-14, 14]}
                    tagOuter="figure">
                {imageRightTop && <Image src={imageRightTop} position="relative" />}
                <div className="image-mask"></div>
                </Parallax>
                </OnVisible>
                {/* <div className="image-accent">
                  <div className="block-one">
                  </div>
                  <div className="block-two">
                  </div>
                </div> */}
              </ParallaxProvider>
              </div>
              <div className="image-wrapper image-wrapper__bottom">
                <ParallaxProvider>
                  <OnVisible percent={50} className="content image-box">
                  <Parallax
                    className="custom-class"
                    y={[-14, 14]}
                    tagOuter="figure">
                  {imageRightBot && <Image src={imageRightBot} position="relative" />}
                  <div className="image-mask"></div>
                  </Parallax>
                </OnVisible>
                </ParallaxProvider>
                  <OnVisible percent={50} bounce={true} onChange={changeColor} />
              </div>
            </div>
          </div>
      </div>
    </section>
  )
}
