import React, { useState } from 'react';
import OnVisible from 'react-on-visible'
import './HomeTextContentArea.scss';
import { Image } from '../../components/Image';
import ArrowDown from '../../components/ArrowDown/ArrowDown';

const isClient = typeof window !== 'undefined';

export const HomeTextContentArea = props => {

  function changeColor() {
    let parentSection = document.querySelector('.home-text-content');
    if (parentSection) {
      setBodyClass('new-bg');
    }
  }

  function setBodyClass(className) {
    if (isClient) {
      let bodyElem = document.querySelector('body');
      window.requestAnimationFrame(function() {
        bodyElem.classList.toggle(className);
        setLightMode(!lightMode)
      })
    }
  }

  const { contentCopy, button, buttonText, toggleOptions, backgroundImage } = props.Content[0];
  const [lightMode, setLightMode] = useState(false)

  return (
    <section className={'home-text-content'} id={'home_text_content_area'}>
      <div className={props.className ? `${props.className} wrapper` : 'content-copy-wrapper wrapper'}>
        <div className={'content-copy'}>
          <h2 dangerouslySetInnerHTML={{__html: contentCopy}} />
        </div>
        <a className='button' href={button}>{buttonText}</a>
        {toggleOptions[0].toggleArrowDown && <ArrowDown lightMode={lightMode} autoMode={true}/>}
        {/* this OnVisible is for handling the background colour change. It acts as a listener on the viewport */}
        <OnVisible percent={100} bounce={true} onChange={changeColor} style={{position: 'absolute', bottom: '-70px'}}/> 
      </div>
      <div className="background-image__wrapper">
      { backgroundImage && (
        <Image className="background-image" src={backgroundImage} />
      ) }
      </div>
    </section>
  )
}
