import React from 'react'
import ScrollLock, { TouchScrollable } from 'react-scrolllock'
import * as Scroll from 'react-scroll'
import './HomeHero.scss'
import ArrowDown from '../../components/ArrowDown/ArrowDown'
import PageGrid from '../../components/PageGrid/PageGrid'
import { isMobileView } from '../../utils/helpers'

let Link = Scroll.Link
let Element = Scroll.Element
let Events = Scroll.Events
let scroll = Scroll.animateScroll
let scrollSpy = Scroll.scrollSpy

class HomeHeroClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isIE: false,
      isFirstHeadingActive: true,
      isSecondHeadingActive: false,
      homeSectionClassName: 'HomeHero',
      lockScroll: false,
      videoLargeSrc: '/videos/LycoV2.mp4',
      videoSmallSrc: '/videos/Lyco480.mp4',
      isMobileView: false,
    }
  } // for scroll delaying
  componentDidMount() {
    // provide breakpoint
    if (isMobileView(490)) {
      this.setState({ isMobileView })
    }

    this.setState({
      isIE:
        '-ms-scroll-limit' in document.documentElement.style &&
        '-ms-ime-align' in document.documentElement.style,
      lockScroll: true,
    })

    window.addEventListener(
      'wheel',
      () => {
        this.debounce(this.handleWheel, 10)
      },
      true
    )
    window.addEventListener(
      'touchmove',
      () => {
        this.debounce(this.handleWheel, 10)
      },
      { passive: false }
    )

    // Scroll lock event registering.
    Events.scrollEvent.register('begin', function(to, element) {})
    Events.scrollEvent.register('end', function(to, element) {})
    scrollSpy.update()
  }
  /* debounce method to prevent rapid firing of window scroll event listener */
  debounce(method, delay) {
    clearTimeout(method._tId) // _tId refers to timer ID
    method._tId = setTimeout(function() {
      method()
    }, delay)
  }

  handleWheel = () => {
    let firstHeading = document.querySelector('.first-header')
    /* Make sure your state change is delayed by the anim transition
     * in the sass file, as unmounting is instant.
     */
    
    const delaySpeed = 445;

    // Unlock scroll after this time
    const scrollReleaseTime = 1200; 
    if (firstHeading) {
      firstHeading.classList.add('fade-up')
      setTimeout(() => {
        this.setState({
          isFirstHeadingActive: false,
          isSecondHeadingActive: true,
        })
      }, delaySpeed)
      /* Remove scroll lock after 1.4s */
      setTimeout(() => {
        this.setState({ lockScroll: false })
      }, scrollReleaseTime)
    }
  }

  scrollMore = value => {
    scroll.scrollMore(value)
  }

  componentWillUnmount() {
    window.removeEventListener('wheel', () => {
      this.debounce(this.handleWheel, 10)
    }, true)
    window.removeEventListener('touchmove', () => {
      this.debounce(this.handleWheel, 10)
    }, true)
    Events.scrollEvent.remove('begin')
    Events.scrollEvent.remove('end')

    // We need to remove this class from body to prevent the added background colour via the class.
    if (document.querySelector('body').classList.contains('new-bg')) {
      document.querySelector('body').classList.remove('new-bg')
    }
  }

  handleClick() {
    if (this.state.lockScroll === false) {
      let windowH = window.innerHeight
      this.scrollMore(windowH)
    } else {
      this.setState({
        isFirstHeadingActive: false,
        isSecondHeadingActive: true,
      })
      window.removeEventListener('wheel', () => {}, true)
      this.setState({ lockScroll: false })
    }
  }

  render() {
    
    const { multiHeading } = this.props

    const {
      isFirstHeadingActive,
      isSecondHeadingActive,
      homeSectionClassName,
      videoLargeSrc,
      videoSmallSrc,
    } = this.state

    const { headerOne, headerTwo } = multiHeading[0]

    return (
      <section
        className={`${homeSectionClassName} ${this.state.isIE ? 'isIE' : ''}`}
        id="home-hero-section"
        onScroll={this.handleScroll}
      >
        <ScrollLock isActive={this.state.lockScroll} />
        <div className="video-wrapper background-wrapper">
          <div className="grid">
            <PageGrid />
          </div>
          <div className="video-overlay"></div>
          <video className="video-element" autoPlay playsInline muted loop>
            <source
              src={isMobileView ? videoSmallSrc : videoLargeSrc}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="content-container">
          <div className="content-position-helper wrapper">
            <div className="content">
              <div className="h1-wrap" id="header-one-scrollable">
                {isFirstHeadingActive && (
                  <h1
                    className={'sliding-header first-header'}
                    dangerouslySetInnerHTML={{ __html: headerOne }}
                  />
                )}
                {isSecondHeadingActive && (
                  <h1
                    className={'sliding-header second-header'}
                    dangerouslySetInnerHTML={{ __html: headerTwo }}
                  />
                )}
              </div>
            </div>
            <div className="arrow-wrap" onClick={e => this.handleClick(e)}>
              <ArrowDown autoMode={false} />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export const HomeHero = props => <HomeHeroClass {...props} />
