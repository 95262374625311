import React from 'react'
import OnVisible, { setDefaultProps } from 'react-on-visible'
import { number } from 'prop-types'
/* ODOMETER PACKAGE ===== */
import Odometer from 'react-odometerjs'
/* This package requires document, check gatsby-node.js for the dummy module loading option
 * as per https://www.gatsbyjs.org/docs/debugging-html-builds/
 *
 * Custom styles for odometer
 */
import './odometer-theme-default.scss'
import './odometer-theme-minimal.scss'
/* ====================== */
import './HomeTextAndCounter.scss'

// setDefaultProps({
//   percent: 50,
// })

class HomeTextAndCounterClass extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      _isVisible: false,
      _isLoaded: false,
      _isMobileView: false,
    }
  }
  componentDidMount() {
    this.setState({ _isLoaded: true })

    // Simple check to change the OnVisible percentage for mobile view
    if (window) {
      if (window.innerWidth < 450) {
        this.setState({ _isMobileView: true })
      }
    }
  }

  toggleMetric() {
    this.setState({ _isVisible: true })
  }

  render() {
    const { copyContent, heading, buttonText, buttonLink, numbers } = this.props
    const { _isLoaded, _isVisible, _isMobileView } = this.state
    // A bit of destructure hell to get the values we need from numbers
    const { metricOne, metricTwo, metricThree } = numbers[0]
    /* metric'X' is an ARRAY containing an object:
     * statistics {
     *  label
     *  value
     *  valuePrefix
     *  valueSuffix
     * }
     * to access, use metricX[0].statistics[0].property ...
     */
    const metricArray = [
      metricOne[0].statistics[0],
      metricTwo[0].statistics[0],
      metricThree[0].statistics[0],
    ]

    return (
      <section
        className={
          this.props.className ? this.props.className : 'home-text-counter'
        }
        id={'home_text_counter'}
      >
        <div className="wrapper">
          <div className="copy-wrapper">
            <h2>{heading}</h2>
            {_isLoaded && (
              <p
                className="copy-wrapper__p"
                dangerouslySetInnerHTML={{ __html: copyContent }}
              />
            )}
          </div>
          <div className="button-wrapper">
            <a href={buttonLink} className="button">
              {buttonText}
            </a>
          </div>
          {/* <OnVisible percent={50} onChange={this.toggleMetric.bind(this)}> */}
          <>
            {_isLoaded && (
              <OnVisible
                onChange={this.toggleMetric.bind(this)}
                className="metric-column__wrapper"
                percent={_isMobileView ? 1 : 50}
              >
                {metricArray.map((metricItem, index) => {
                  let metricNumber = 0
                  // By triggering an _isVisible state change we cause a component re-render
                  if (_isVisible) {
                    metricNumber = metricItem.value
                  }
                  return (
                    <OnVisible key={index} className="metric-column">
                      <div className="metric-value notranslate">
                        {metricItem.valuePrefix ? (
                          <span>{metricItem.valuePrefix}</span>
                        ) : null}
                        {_isLoaded && (
                          <Odometer
                            value={metricNumber}
                            theme="minimal"
                            format={'dddd'}
                            duration={6000}
                          />
                        )}
                        {metricItem.valueSuffix ? (
                          <span>{metricItem.valueSuffix}</span>
                        ) : null}
                      </div>
                      <div className="metric-copy">
                        <p>{metricItem.label}</p>
                      </div>
                    </OnVisible>
                  )
                })}
              </OnVisible>
            )}
            {/* </OnVisible> */}
          </>
        </div>
      </section>
    )
  }
}

export const HomeTextAndCounter = props => (
  <HomeTextAndCounterClass {...props} />
)
