import React from 'react';
import './KeyStatisticsCounter.scss';
// import CountUp from 'react-countup';
import CustomCountUp from '../components/CustomCountUp'

export const KeyStatisticsCounter = props => {
  const { 
    statistics
  } = props
  return (
    <section className="KeyStatisticsCounter">
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="content stat-box">
            {statistics.map((item, index) => {
                const {
                  label,
                  valuePrefix,
                  value,
                  valueSuffix
                } = item;
                return (
                  <div className="stat-item" key={index}>
                    <span className="value">{valuePrefix}<CustomCountUp value={value} duration={5}/>{valueSuffix}</span>
                    <span className="label">{label}</span>
                  </div>
                );
              })}
            </div>
        </div>
      </div>
    </section>
  )
}
