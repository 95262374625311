import React, { Component } from 'react'
import * as Scroll from 'react-scroll';
import './ArrowDown.scss';

let Link       = Scroll.Link;
let Element    = Scroll.Element;
let Events     = Scroll.Events;
let scroll     = Scroll.animateScroll;
let scrollSpy  = Scroll.scrollSpy;

class ArrowDown extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      isIE: false,
    };

  }
  componentDidMount() {
    this.setState({
      isIE: '-ms-scroll-limit' in document.documentElement.style && '-ms-ime-align' in document.documentElement.style
    })
    Events.scrollEvent.register('begin', function(to, element) {
      
    });
    Events.scrollEvent.register('end', function(to, element) {
      
    });
    scrollSpy.update();
  }
  
  componentWillUnmount() {
    Events.scrollEvent.remove('begin');
    Events.scrollEvent.remove('end');
  }
  
  onClick = event => { 
    this.scrollToNextSection(event);
  }
  
  scrollToNextSection(e) {
    // if automode is set to true then let the arrow down handle scrolling down the page.
    // if automode is disabled then you must specify your own scrollMore when using arrowdown
    if (this.props.autoMode) {
      let windowH = window.innerHeight;
      scroll.scrollMore(windowH);
    } else {
      return null;
    }
  }

  getNextSection(element) {
    return element.nextElementSibling;
  }

  render() {
    const { className, width, height, nextSectionId, autoMode, lightMode } = this.props;
    const arrowWhiteSrc = "/white_Scroll_Down.svg" || ''
    const arrowDarkSrc = "/dark_Scroll_Down.svg" || ''
    const activeArrowImageSrc = lightMode ? arrowDarkSrc : arrowWhiteSrc
    return (
      <div className={className ? className : 'arrow-down'} onClick={()=> this.scrollToNextSection({autoMode, nextSectionId})}>
        <a to={nextSectionId ? nextSectionId : ''}>
          <img width={width} alt="Scroll Down Arrow" height={height} src={activeArrowImageSrc} />
        </a>
      </div>
    )
  }
}

export default ArrowDown;