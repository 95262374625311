import React from 'react';
import CountUp from 'react-countup';

export default class CustomCountUp extends React.Component {
  render() {
    const { value, duration, delay, ...other } = this.props
    //var start = Math.pow(10, parseInt(value).toString(10).length - 1)
    var start = Math.abs(parseInt(value) - 80);
    var end = parseInt(value)
    return (
        <CountUp className="num-container" start={start} end={end} duration={duration} delay={delay} onUpdate={ this.reset } formattingFn={(num) => { return [...num.toString(10)].map( char => { return `<span class="counter-char">${char}</span>` }).join('') }} />
    )
  }
}
