// This is labeled as 'Home Intro' in ACF page builder

import React from 'react';
import './LargeBackgroundWithTextAndStats.scss';
import { Image } from '../components/Image';
import { Link } from 'gatsby'
import OnVisible, { setDefaultProps, onChange } from 'react-on-visible';
import CustomCountUp from '../components/CustomCountUp'
import { ParallaxProvider } from 'react-scroll-parallax'
import { Parallax } from 'react-scroll-parallax'

setDefaultProps({
    percent: 25
});

export const LargeBackgroundWithTextAndStats = props => {
  const {
    backgroundImage,
    subheadingOne,
    headingOne,
    contentOne,
    buttonOne,
    buttonTextOne,
    subheadingTwo,
    headingTwo,
    contentTwo,
    buttonTwo,
    buttonTextTwo,
    statistics,
    image
  } = props;

  return (
    <section className="LargeBackgroundWithTextAndStats">
      <div className="background-helper"></div>
      <div className="wrapper">
        <div className="content-overlay">
          <div className="flex-row">
            <OnVisible className="content text-box tb1">
            {/* <div className="content text-box tb1"> */}
              <span className="subtitle" dangerouslySetInnerHTML={{__html: subheadingOne}} />
              <h2 className="title" dangerouslySetInnerHTML={{__html: headingOne}} />
              <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: contentOne}} />
              {buttonOne && (
                    <Link
                      to={buttonOne}
                      activeClassName="active"
                      className="button on-dark"
                      aria-current="true"
                    >
                      <span>{ buttonTextOne ? buttonTextOne : 'Learn More' }</span>
                    </Link>
                )}
              {/* </div> */}
            </OnVisible>
            <OnVisible className="content stat-box" percent={20}>
                {statistics.map((item, index) => {
                  const {
                    label,
                    prefix,
                    value,
                    suffix
                  } = item;
                  return (
                    <div className="stat-item" key={index}>
                      <span className="value">{prefix}<CustomCountUp value={value} duration={3} delay={0.1} />{suffix}</span>
                      <span className="label">{label}</span>
                    </div>
                  );
                 })
                }
            {/* </div> */}
            </OnVisible>
          </div>
            <div className="flex-row">
              <ParallaxProvider>
                <OnVisible percent={70} className="content image-box">
                  <Parallax
                    className="custom-class"
                    y={[-16, 16]}
                    tagOuter="figure">
                    {/* { image && (
                      <Image src={image} />
                    ) } */}
                    {image && <Image src={image} />}
                    <div className="image-mask"></div>
                  </Parallax>
                </OnVisible>
              </ParallaxProvider>
              <OnVisible className="content text-box tb2">
              {/* <div className="content text-box tb2"> */}
                <span className="subtitle" dangerouslySetInnerHTML={{__html: subheadingTwo}} />
                <h2 className="title" dangerouslySetInnerHTML={{__html: headingTwo}} />
                <div className="wysiwygcontent" dangerouslySetInnerHTML={{__html: contentTwo}} />
                {buttonTwo && (
                      <Link
                        to={buttonTwo}
                        activeClassName="active"
                        className="button on-dark"
                        aria-current="true"
                      >
                        <span>{ buttonTextTwo ? buttonTextTwo : 'Learn More' }</span>
                      </Link>
                  )}
                {/* </div> */}
                </OnVisible>
            </div>
        </div>
      </div>
      { backgroundImage && (
        <Image className="background-image" src={backgroundImage} style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%",
          zIndex: "-1"
        }}
        />
      ) }
    </section>
  )
}
