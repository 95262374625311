import React, { useState } from 'react'
import './ReportList.scss'

/* There is an ACF field: toggle_capital_case
 * which is used to determine the titles of reports
 as 'In Capital Case'. Style is applied inline to conform.
 This is not title case.
 */


export const ReportList = props => {
  const { reportContent, toggle_capital_case } = props;
  const [tabs, setActiveTab] = useState(reportContent);

  const handleExpand = (index) => {
    const oldValues = tabs;
    const newValues = oldValues.map((val, key) => index == key ? { ...val, default: !val.default } : val);
    setActiveTab(newValues);
  }

  return (
    <section
      className={props.className ? props.className : 'report-list__container'}>
      <div
        className={`wrapper report-list__container__wrapper${toggle_capital_case ? ' capital_case' : ''}`}>
        {tabs && tabs.length > 0 &&
          tabs.map((reportGroup, index) =>
            <div className="report-year-group" key={index}>
              <div className="year__wrapper">
                <h2>{reportGroup.year}</h2>
                <button onClick={() => handleExpand(index)} className={reportGroup.default ? 'expanded' : ''}></button>
              </div>
              <div className={`report-row__wrapper${reportGroup.default ? ' expanded' : ''}`}>
                {reportGroup?.reports?.map((item, index) =>
                  <div className="report-row">
                    <div className="title">{item.reportTitle}</div>
                    {/* We need to check for a file before rendering the download button */}
                    <div className="report-row__button-wrapper">
                      {item.reportDate && <span className="report-row__date">{item.reportDate}</span>}
                      {item.reportTitle && item.reportFile.url && (
                        <a className="button button-download" target="_blank" href={item.reportFile.url}>Download</a>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    </section>
  )
}
